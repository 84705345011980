define('quizzes-addon/components/player/qz-player-footer', ['exports', 'ember', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig) {

  /**
   * Player navigation
   *
   * Component responsible for showing simple navigation controls (i.e. back/next)
   * for the player. It may embed other components for interacting with the player.
   *
   * @module
   * @see controllers/player.js
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    didRender: function didRender() {
      var component = this;
      component.$('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
    },

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['qz-player-footer'],

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Action triggered when the user change the emotion
       * @see qz-emotion-picker
       */
      changeEmotion: function changeEmotion(emotionScore) {
        var component = this;
        var resource = component.get('resource');
        var unicode = component.selectedEmotionUnicode(emotionScore);
        component.$('#resource-' + resource.id).find('svg use').attr('xlink:href', '/assets/quizzes-addon/emoji-one/emoji.svg#' + unicode);
        this.sendAction('onChangeEmotion', emotionScore);
      },

      /**
       * Action triggered for the next button hover
       */
      onShowFeedback: function onShowFeedback() {
        this.set('isShowFeedback', true);
      },

      /**
       * Action triggered for the next button move out
       */
      onCloseFeedback: function onCloseFeedback() {
        this.set('isShowFeedback', false);
      },

      /**
       * Action triggered when the user open the navigator panel
       */
      onOpenNavigator: function onOpenNavigator() {
        var component = this;
        component.set('isNavigatorOpen', true);
        component.$('.list-resources').slideDown();
      },

      /**
       * Action triggered when the user close the navigator panel
       */
      onCloseNavigator: function onCloseNavigator() {
        var component = this;
        component.set('isNavigatorOpen', false);
        component.$('.list-resources').slideUp();
      },

      onToggleResourceNav: function onToggleResourceNav() {
        var component = this;
        component.$('.list-resources').slideToggle();
        component.toggleProperty('isNavigatorOpen');
        if (component.get('isNavigatorOpen')) {
          $('.player-narration').addClass('naration-panel');
        }
      },

      /**
       *
       * Triggered when an item is selected
       * @param item
       */
      selectItem: function selectItem(item) {
        this.selectItem(item.resource);
      },

      /**
       * Action triggered when the user wants to finish the collection
       */
      finishCollection: function finishCollection() {
        var component = this;
        component.sendAction('onFinishCollection');
      },

      /**
       * Action triggered when the user clicks at see usage report
       */
      seeUsageReport: function seeUsageReport() {
        var component = this;
        component.sendAction('onFinishCollection');
      },
      /***
       * Return to previous resource
       * @param {Resource} question
       */
      previousResource: function previousResource() {
        var component = this;
        component.$('.content').scrollTop(0);
        component.sendAction('onPreviousResource', component.get('resource'));
      },

      /***
       * Return to previous resource
       * @param {Resource} question
       */
      nextResource: function nextResource() {
        var component = this;
        component.$('.content').scrollTop(0);
        component.sendAction('onNextResource', component.get('resource'));
      },

      /**
       * Action triggered when toggle screen mode
       */
      onToggleScreen: function onToggleScreen() {
        var component = this;
        component.toggleScreenMode();
      },

      onExit: function onExit() {
        window.parent.postMessage(_quizzesAddonConfigQuizzesConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE, '*');
      },

      start: function start() {
        this.sendAction('onStartPlayer');
      },

      playNext: function playNext() {
        this.sendAction('onPlayNext');
      },
      emptyNextPlay: function emptyNextPlay() {
        this.sendAction('onEmptyNextPlay');
      },

      showTimer: function showTimer() {
        this.sendAction('showTimer');
      },

      showRelatedContentContainer: function showRelatedContentContainer() {
        this.sendAction('showRelatedContentContainer');
      },

      showNarrationContainer: function showNarrationContainer() {
        this.sendAction('showNarrationContainer');
      },

      showFeedbackContainer: function showFeedbackContainer() {
        this.sendAction('showFeedbackContainer');
      },

      toggleLeftPanel: function toggleLeftPanel() {
        this.toggleProperty('isOpenLeftPanal');
      },

      onDiagnosticNext: function onDiagnosticNext() {
        if (this.get('showNext') && !this.get('isDiagnosticEnd')) {
          this.send('nextResource');
        } else {
          this.sendAction('onDiagnosticNext');
        }
      }
    },

    // -------------------------------------------------------------------------
    // Events

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {string} on change emotion action
     */
    onChangeEmotion: 'onChangeEmotion',

    /**
     * @property {number} The rating score for the current resource
     */
    ratingScore: 0,

    /**
     * Indicates if changes can be made
     * @property {boolean} readOnly
     */
    readOnly: false,

    /**
     * @property {Collection}
     */
    collection: null,

    /**
     * @property {String} It will decided to show react widget or not
     */
    showReactButton: true,

    /**
     * @property {String} selectedResourceId - resource Id selected
     */
    selectedResourceId: null,

    /**
     * @property {string|function} onItemSelected - event handler for when an item is selected
     */
    onItemSelected: null,

    /**
     * Indicates when the collection is already submitted
     * @property {boolean}
     */
    submitted: false,

    /**
     * Resource result for the selected resource
     * @property {ResourceResult}
     */
    resourceResults: _ember['default'].A([]),

    hasAllCards: _ember['default'].computed('diagnosticActive', 'feedbackCategory', 'isShowActivityFeedback', function () {
      var showConfirmation = this.get('showConfirmation');
      var suggestedResources = this.get('suggestedResources');
      var diagnosticActive = this.get('diagnosticActive');
      var isCollection = this.get('isCollection');
      var feedbackCategory = this.get('feedbackCategory');
      var isShowActivityFeedback = this.get('isShowActivityFeedback');
      return !showConfirmation && suggestedResources && isCollection && !diagnosticActive && feedbackCategory && isShowActivityFeedback;
    }),

    /**
     * A convenient structure to render the menu
     * @property
     */
    resourceItems: _ember['default'].computed('collection', 'resourceResults.@each.value', 'selectedResourceId', 'showFinishConfirmation', function () {
      var component = this;
      var collection = component.get('collection');
      var resourceResults = component.get('resourceResults');
      return resourceResults.map(function (resourceResult) {
        var resourceId = resourceResult.get('resource.id');
        var ratingScore = resourceResult.get('reaction');
        return {
          resource: collection.getResourceById(resourceId),
          started: resourceResult.get('started'),
          isCorrect: resourceResult.get('isCorrect'),
          selected: resourceId === component.get('selectedResourceId'),
          unicode: component.selectedEmotionUnicode(ratingScore),
          isResource: resourceResult.get('isResource')
        };
      });
    }),
    /**
     * @property
     */
    resourceButton: _ember['default'].computed('resource', 'isShowStudyTimerWidget', 'suggestedResources', 'isShowActivityFeedback', function () {
      var component = this;
      var isShowStudyTimerWidget = component.get('isShowStudyTimerWidget');
      var suggestedResources = component.get('suggestedResources');
      var isShowActivityFeedback = component.get('isShowActivityFeedback');
      var resource = component.get('resource');
      return isShowStudyTimerWidget && resource && resource.isResource && suggestedResources && isShowActivityFeedback;
    }),

    /**
     * Should resource links in the navigator be disabled?
     * @property {boolean}
     */
    isNavigationDisabled: false,

    /**
     * It will maintain the state of navigator pull up
     * @property {boolean}
     */
    isNavigatorOpen: false,

    /**
     * Property used to  identify whether collection object has items to play.
     */
    hasCollectionItems: _ember['default'].computed('collection', function () {
      var collection = this.get('collection');
      var contentCount = this.getResourceQuestionCount(collection.resources);
      var resourceCount = contentCount.resourceCount;
      var questionCount = contentCount.questionCount;
      var hasCollectionItems = false;
      var isCollection = this.get('collection.isCollection');
      if (isCollection && (resourceCount > 0 || questionCount > 0)) {
        hasCollectionItems = true;
      } else if (questionCount > 0) {
        hasCollectionItems = true;
      }
      return hasCollectionItems;
    }),

    /**
     * @property {Boolean} Indicate if the context has more attempts available
     */
    noMoreAttempts: _ember['default'].computed('collection.isAssessment', 'collection.attempts', 'attempts', function () {
      return this.get('collection.isAssessment') && this.get('collection.attempts') > 0 && this.get('attempts') && this.get('attempts') >= this.get('collection.attempts');
    }),

    playAttempts: _ember['default'].computed('collection', 'lesson', 'lessonList', 'unitList', function () {
      var component = this;
      var collection = component.get('collection');
      var lesson = component.get('lesson');
      var lessonDetails = component.get('lessonList');
      var unitList = component.get('unitList');
      var lessonLength = lessonDetails.length;
      var activeLesson = lessonDetails.findBy('id', lesson.get('id'));
      var lIndex = lessonDetails.findIndex(function (item) {
        return item.id === lesson && lesson.get('id');
      });
      var lessonIndex = lIndex + 1;
      var unitLength = unitList.length;
      var uIndex = unitList.findIndex(function (item) {
        return item.id === activeLesson && activeLesson.get('unit_id');
      });
      var unitIndex = uIndex + 1;
      var lessonData = lesson.get('children');
      var collectionLength = lessonData.length;
      var activeCollection = lessonData.findBy('id', collection.get('id'));
      var cIndex = lessonData.findIndex(function (item) {
        return item.id === collection && collection.get('id');
      });
      var collectionIndex = cIndex + 1;
      var isMilestoneLesson = lesson && lesson.get('isMilestoneLesson');
      return isMilestoneLesson ? true : activeCollection && activeCollection.get('resourceCount') === 0 && activeCollection && activeCollection.get('questionCount') === 0 && unitIndex === unitLength && lessonLength === lessonIndex && collectionLength === collectionIndex;
    }),

    isDiagnosticEnd: false,

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Triggered when a resource item is selected
     * @param {Resource} resource
     */
    selectItem: function selectItem(resource) {
      if (resource && !this.get('isNavigationDisabled')) {
        if (this.get('onItemSelected')) {
          this.sendAction('onItemSelected', resource);
        }
      }
    },

    /**
     * Find selected emotion unicode from rating score
     * @type {{String}}
     */
    selectedEmotionUnicode: function selectedEmotionUnicode(ratingScore) {
      if (ratingScore) {
        var selectedEmotion = _quizzesAddonConfigQuizzesConfig.EMOTION_VALUES.findBy('value', ratingScore);
        return selectedEmotion.unicode;
      }
      return 'no-reaction';
    },

    /**
     * @function toggleScreenMode
     * Method to toggle fullscreen mode
     */
    toggleScreenMode: function toggleScreenMode() {
      var component = this;
      _ember['default'].$('body.study-player').toggleClass('fullscreen');
      component.toggleProperty('isFullScreen');
    },

    /**
     * @function getResourceQuestionCount
     * Method to get resource and question count from the collection
     */
    getResourceQuestionCount: function getResourceQuestionCount(resources) {
      var questionCount = 0;
      var resourceCount = 0;
      if (_ember['default'].isArray(resources)) {
        resources.map(function (resource) {
          if (resource.isResource) {
            resourceCount++;
          } else {
            questionCount++;
          }
        });
      }
      return {
        questionCount: questionCount,
        resourceCount: resourceCount
      };
    },

    /**
     *  @property {Object} Extracted the unit title from unit
     */
    unitTitle: _ember['default'].computed(function () {
      var unit = this.get('unit');
      if (unit) {
        return _ember['default'].Object.create({
          shortname: 'U' + unit.get('sequence'),
          fullname: unit.get('title')
        });
      }
      return null;
    }),

    /**
     *  @property {Object} Extracted the lesson title from lesson
     */
    lessonTitle: _ember['default'].computed(function () {
      var lesson = this.get('lesson');
      if (lesson) {
        return _ember['default'].Object.create({
          shortname: 'L' + lesson.get('sequence'),
          fullname: lesson.get('title')
        });
      }
      return null;
    }),

    /**
     *  @property {Object} Extracted the collection title from unit, lesson and/or collection
     */
    collectionTitle: _ember['default'].computed(function () {
      var collection = this.get('collection');
      var lesson = this.get('lesson');
      var title = null;
      if (lesson) {
        var lessonChildren = lesson.children;
        var isChild = lessonChildren.findBy('id', collection.id);
        if (collection && isChild) {
          if (collection.isCollection === true || collection.collectionType === 'collection') {
            var collections = lessonChildren.filter(function (collection) {
              return collection.format === 'collection';
            });
            collections.forEach(function (child, index) {
              if (child.id === collection.id) {
                var collectionSequence = index + 1;
                title = _ember['default'].Object.create({
                  shortname: 'C' + collectionSequence,
                  fullname: collection.get('title')
                });
              }
            });
          } else {
            var assessments = lessonChildren.filter(function (assessment) {
              return assessment.format === 'assessment';
            });
            assessments.forEach(function (child, index) {
              if (child.id === collection.id) {
                var assessmentSequence = index + 1;
                title = _ember['default'].Object.create({
                  shortname: 'A' + assessmentSequence,
                  fullname: collection.get('title')
                });
              }
            });
          }
        }
      }
      if (!title) {
        title = _ember['default'].Object.create({
          shortname: collection.isCollection === true || collection.collectionType === 'collection' ? 'C' : 'A',
          fullname: collection.get('title')
        });
      }

      return title;
    }),

    /**
     * @property {boolean} isNextEnabled check whether next button is enabled or not
     */
    isNextEnabled: true,

    /**
     * @property {Boolean} isMilestoneContent
     * Property to check whether the served content is from milestone or not
     */
    isMilestoneContent: _ember['default'].computed.alias('lesson.isMilestoneLesson'),

    /**
     * @property {String} domainName
     * Domain Name of the Lesson when it's tagged with milestone
     */
    domainName: _ember['default'].computed.alias('lesson.domainName'),

    /**
     * @property {String} gradeName
     * Grade Name of the Lesson when it's tagged with milestone
     */
    gradeName: _ember['default'].computed.alias('lesson.gradeName'),

    /**
     * @property {String} gradeSubject
     * Grade Subject of the Milestone
     */
    gradeSubject: _ember['default'].computed(function () {
      var component = this;
      var courseTaxonomies = component.get('course.taxonomy');
      return courseTaxonomies.length ? courseTaxonomies.objectAt(0).parentTitle : null;
    })
  });
});