define('quizzes-addon/components/player/related-content', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Related Content
   *
   * Component responsible for showing related content.
   *
   * @module
   * @see controllers/player.js
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['related-content resource-child'],

    // -------------------------------------------------------------------------
    // Properties

    showRelatedContent: false,
    /**
     *Back drop for the related content
     */
    isShowBackdrop: false,

    /**
     * @property {Array} list of suggested resources of a collection
     */
    suggestedResources: null,

    /**
     * @property {String} classId - Class unique Id associated for the collection / assessment.
     */
    classId: null,

    /**
     * @property {String} courseId - course unique Id associated for the collection / assessment.
     */
    courseId: null,

    /**
     * @property {String} collectionUrl
     */
    collectionUrl: null,

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Action triggered when the user click related content tab.
       */
      onToggleRelatedContent: function onToggleRelatedContent() {
        this.toggleProperty('showRelatedContent');
      },

      /**
       * Action triggered when the user play a resource
       */
      onPlayResource: function onPlayResource(resource) {
        var collectionUrl = window.location.href;
        if (!this.get('collectionUrl')) {
          this.set('collectionUrl', collectionUrl);
        }
        var queryParams = {
          collectionUrl: this.get('collectionUrl')
        };
        var classId = this.get('classId');
        if (classId) {
          queryParams.classId = classId;
        }
        var isIframeMode = this.get('isIframeMode');
        if (isIframeMode) {
          queryParams.isIframeMode = isIframeMode;
        }
        this.get('router').transitionTo('resource-player', this.get('courseId'), resource.id, {
          queryParams: queryParams
        });
      },

      clossRelatedContent: function clossRelatedContent() {
        this.sendAction('showRelatedContentContainer');
      }
    },

    // -------------------------------------------------------------------------
    // Events

    // -------------------------------------------------------------------------
    // Methods

    //--------------------------------------------------------------------------
    // Observer

    onChange: _ember['default'].observer('showRelatedContent', function () {
      var _this = this;

      if (this.get('showRelatedContent')) {
        (function () {
          var component = _this;
          component.$().animate({
            bottom: '50px'
          }, {
            complete: function complete() {
              // component.$().css('bottom', '50px');
              component.set('isShowBackdrop', true);
            }
          });
        })();
      } else {
        (function () {
          var component = _this;
          //check height and set bottom position based on orientation for mobile view
          var bottom = component.$().height() > 245 ? -385 : -150;
          component.$().animate({
            bottom: bottom + 'px'
          }, {
            complete: function complete() {
              // component.$().css('bottom', `${bottom}px`);
              component.set('isShowBackdrop', false);
            }
          });
        })();
      }
    })
  });
});