define('quizzes-addon/models/resource/resource', ['exports', 'ember', 'quizzes-addon/config/quizzes-question', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonConfigQuizzesQuestion, _quizzesAddonConfigQuizzesConfig) {

  /**
   * Resource Model
   *
   * @typedef {Object} Resource
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     * List of possible answers/choices
     * @property {Answer[]} answers
     */
    answers: _ember['default'].A(),

    /**
     * @property {string} body
     */
    body: null,

    /**
     * list of correct answers
     * @property {Answer[]}
     */
    correctAnswer: _ember['default'].A(),

    /**
     * list of correct answers
     * @property {Answer[]}
     */
    baseAnswers: _ember['default'].A(),

    /**
     * @property {String} description
     */
    description: null,

    /**
     * resource id
     * @property {string} id
     */
    id: null,

    /**
     * indicates if the object is a question
     * @property {boolean} isQuestion
     */
    isQuestion: _ember['default'].computed.not('isResource'),

    /**
     * indicates if the object is a resource
     * @property {boolean} isResource
     */
    isResource: null,

    /**
     * Number of choices that can be selected
     * @property {boolean} shuffle
     */
    maxChoices: 0,

    /**
     * @property {string}
     */
    narration: null,

    /**
     * @property {string} ownerId
     */
    ownerId: null,

    /**
     * @property {Profile} owner
     */
    owner: null,

    /**
     * Text to show just before the answers
     * @property {boolean} shuffle
     */
    prompt: null,

    /**
     * @property {number} sequence
     */
    sequence: null,

    /**
     * If the answers should be shuffled
     * @property {boolean} shuffle
     */
    shuffle: false,

    /**
     * @property {string} title
     */
    title: null,

    /**
     * Indicates the resource or question type.
     * @property {string} type
     */
    type: null,

    /**
     * @property {JSONObject}
     */
    displayGuide: null,

    subQuestions: _ember['default'].A([]),

    /**
     * @property {String} questionText used to show text on choose one question right side
     */
    questionText: null,

    softText: null,

    hardText: null,

    metadata: null,

    htmlContent: null,

    showScore: false,

    product: null,

    // -------------------------------------------------------------------------
    // Computed

    /**
     * Indicates the resource format. i.e image, text, video, interaction, webpage, question
     * @property {string} format
     */
    format: _ember['default'].computed('isResource', 'type', function () {
      return this.get('isResource') ? this.get('type') : 'question';
    }),

    /**
     * Indicates if the question has answers
     * @property {boolean}
     */
    hasAnswers: _ember['default'].computed.bool('answers.length'),

    /**
     * Indicates if the question has answers
     * @property {boolean}
     */
    hasCorrectResponse: _ember['default'].computed.bool('correctResponse.length'),

    /**
     * Indicates if the resource has a narration
     * @property {boolean}
     */
    hasNarration: _ember['default'].computed.bool('narration'),

    /**
     * Indicates if the question has owner
     * @property {boolean}
     */
    hasOwner: _ember['default'].computed.bool('owner'),

    /**
     * @property {boolean} indicates if the question is fill in the blank type
     * @see components/player/qz-fib.js
     */
    isFIB: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.fib),
    /**
     * @property {boolean} indicates if the question is scientific fill in the blank type
     * @see components/player/qz-scientific-fill-in-the-blank.js
     */
    isScientificFIB: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.scientificfib),

    /**
     * @property {boolean} indicates if the question is hot spot image type
     * @see components/player/gru-hot-spot-image.js
     */
    isHotSpotImage: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.hotSpotImage),

    /**
     * @property {boolean} indicates if the question is hot spot text type
     * @see components/player/gru-hot-spot-text.js
     */
    isHotSpotText: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.hotSpotText),

    /**
     * @property {boolean} indicates if the question is hot spot text
     * @see components/player/qz-hot-text-highlight.js
     */
    isHotTextHighlight: _ember['default'].computed.or('isHotTextHighlightWord', 'isHotTextHighlightSentence'),

    /**
     * @property {boolean} indicates if the question is hot text word type
     */
    isHotTextHighlightWord: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.hotTextHighlightWord),

    /**
     * @property {boolean} indicates if the question is hot text sentence type
     */
    isHotTextHighlightSentence: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.hotTextHighlightSentence),

    /**
     * @property {boolean} indicates if the question is reorder
     * @see components/player/qz-reorder.js
     */
    isHotTextReorder: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.hotTextReorder),

    /**
     * Indicates if it is an image resource
     * @property {boolean}
     */
    isImageResource: _ember['default'].computed('resourceType', function () {
      var type = this.get('resourceType');
      return type && type.indexOf('image') >= 0;
    }),

    /**
     * @property {boolean} indicates if the question is single choice type
     * @see components/player/qz-single-choice.js
     */
    isSingleChoice: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.singleChoice),

    /**
     * @property {boolean} indicates if the question is multiple answer type
     * @see components/player/qz-multiple-answer.js
     */
    isMultipleAnswer: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.multipleAnswer),

    /**
     * @property {boolean} indicates if the question is open ended type
     * @see components/player/qz-open-ended.js
     */
    isOpenEnded: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.openEnded),

    /**
     * @property {boolean} indicates if the question is likert type
     * @see components/player/qz-likert-scale.js
     */
    isLikertScale: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.likertScale),

    /**
     * @property {boolean} indicates if the question is comprehension type
     * @see components/player/qz-comprehension.js
     */
    isComprehension: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.serpComprehension),

    /**
     * Indicates if it is an pdf resource
     * @property {boolean}
     */
    isPDFResource: _ember['default'].computed.equal('resourceType', _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.pdf),

    /**
     * Indicates if it is an pdf resource
     * @property {boolean}
     */
    isHtmlResource: _ember['default'].computed.equal('resourceType', _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.html),

    /**
     * @property {boolean} indicates if the question is true false type
     * @see components/player/qz-true-false.js
     */
    isTrueFalse: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.trueFalse),
    /**
     * @property {boolean} indicates if the question is scientific free response type
     * @see components/player/qz-scientific-free-response.js
     */
    isScientificFreeResponse: _ember['default'].computed.equal('type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.scientificFreeResponse),
    /**
     * Indicates if it is an url resource
     * @property {boolean}
     */
    isUrlResource: _ember['default'].computed.equal('resourceType', _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.url),

    /**
     * Indicates if it is an vimeo resource
     * @property {boolean}
     */
    isVimeoResource: _ember['default'].computed.equal('resourceType', _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.vimeo),

    /**
     * Indicates if it is an youtube resource
     * @property {boolean}
     */
    isYoutubeResource: _ember['default'].computed.equal('resourceType', _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.youtube),

    /**
     * @property {String} Indicates the resource type. i.e video/youtube, assessment-question, image/png
     */
    resourceType: _ember['default'].computed('type', function () {
      var format = this.get('type');
      var resourceUrl = this.get('body');
      var youtubePattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      var vimeoPattern = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/;
      var pdfPattern = /.*\.pdf/;
      var resourceType = _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.url; // Default type
      if (resourceUrl) {
        if (youtubePattern.test(resourceUrl)) {
          return _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.youtube;
        }
        if (vimeoPattern.test(resourceUrl)) {
          return _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.vimeo;
        }
        switch (format) {
          case _quizzesAddonConfigQuizzesConfig.RESOURCE_TYPES.audio:
          case _quizzesAddonConfigQuizzesConfig.RESOURCE_TYPES.interactive:
          case _quizzesAddonConfigQuizzesConfig.RESOURCE_TYPES.webpage:
          case _quizzesAddonConfigQuizzesConfig.RESOURCE_TYPES.video:
            resourceType = _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.url; // Default type
            break;
          case _quizzesAddonConfigQuizzesConfig.RESOURCE_TYPES.image:
            resourceType = pdfPattern.test(resourceUrl) ? _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.pdf : _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.image;
            break;
          case _quizzesAddonConfigQuizzesConfig.RESOURCE_TYPES.text:
            resourceType = _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.pdf;
            break;
          case _quizzesAddonConfigQuizzesConfig.RESOURCE_TYPES.html:
            resourceType = _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.html;
            break;
          default:
            resourceType = _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.url; // Default type
        }
      }
      if (format === _quizzesAddonConfigQuizzesConfig.RESOURCE_TYPES.html) {
        resourceType = _quizzesAddonConfigQuizzesConfig.QUIZZES_RESOURCE_TYPES.html;
      }
      return resourceType;
    }),

    /**
     * @property {String}
     */
    mediaUrl: null,

    /**
     * @type {Boolean}
     */
    hasMedia: _ember['default'].computed.bool('mediaUrl')
  });
});